export default {
  "login.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés"])},
  "login.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A rendszerbe való bejelentkezéshez kérem adja meg felhasználó nevét és jelszavát."])},
  "login.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail cím"])},
  "login.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó"])},
  "login.reveal_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutat"])},
  "login.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés"])},
  "login.error_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sikertelen bejelentkezés"])},
  "login.error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem sikerült végrehajtani a bejelentkezést, mert a megadott felhasználói adatok hibásak vagy lejártak. A probléma megoldásához, kérjük, jelentkezzen be az asztali alkalmazásba, majd próbálja újra."])},
  "setup.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csatlakozási beállítás"])},
  "setup.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg a site URL-t amihez csatlakozni szeretne."])},
  "setup.host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site URL"])},
  "setup.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Következő lépés"])},
  "setup.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A site URL nem létezik vagy hibás. Kérjük adja meg ismét az adatot, vagy vegye fel a kapcsolatot GLABs adminisztrátorával!"])},
  "locale.hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magyar"])},
  "locale.en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])}
}