<template>
	<div class="row no-gutters">
		<div class="col-lg-7 col-md-5 hidden-sm hidden-xs page-left-outer">
			<div class="page-left"></div>
		</div>
		<div class="col-lg-5 col-md-7 col-sm-12 page-right-outer">
			<div class="page-right">
				<div class="header">
					<img class="logo" src="/auth/images/glabs-logo-standalone.svg">
					<div class="btn-group locale-switch">
						<button type="button" class="btn btn-link dropdown-toggle locale-toggle" data-toggle="dropdown"
								aria-haspopup="true" aria-expanded="false">
							<span class="locale" :class="'locale-'+getLocale($i18n.locale)"></span>
							<span class="locale-switch-text">
								<span class="caret"></span>
							</span>
						</button>
						<ul class="dropdown-menu pull-right">
							<li v-for="(lang, i) in langs">
								<a href="#" class="locale-switch-option" data-locale="{{ lang }}"
								   v-on:click="changeLocale(lang)">
									<span v-bind:class="'locale locale-' + lang"></span> {{ $t('locale.'+lang) }}
								</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="content">
					<router-view/>
				</div>
				<div class="footer">
					GLABs Loading Management
					<span class="nowrap">
						- support@glabs.me
					</span>
					<span class="nowrap">
						- https://glabs.me
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				langs: ['en', 'hu'],
				error: window.error,
			}
		},
		methods: {
			changeLocale(lang) {
				this.$i18n.locale = lang;
			},
			getLocale(browserLocale) {
				if (this.langs.includes(browserLocale)) {
					return browserLocale;
				}

				return "en";
			}
		}
	}
</script>
