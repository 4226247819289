<template>
	<div class="form">
		<form method="post">
			<h1>{{ $t('setup.title') }}</h1>
			<p>{{ $t('setup.subtitle') }}</p>
			<div class="alert alert-danger" v-if="$root.error">
				<img src="/auth/images/authentication/icon-alert.svg" class="warning-img">
				<p class="error-message"><b>{{ $t('setup.error') }}</b></p>
			</div>
			<div class="form-group">
				<label for="SiteURL">
					{{ $t('setup.host') }}
				</label>


				<div class="input-group">
					<span class="input-group-addon">https://</span>
					<input type="text" name="host" id="SiteURL" required="required" placeholder="companyname.glabs.me"
						   class="form-control site-url">
				</div>

			</div>
			<div class="row form-actions">
				<div class="col-sm-12 col-xs-12">
					<button type="submit" class="btn btn-block btn-lg btn-aperture btn-primary">
						<span>{{ $t('setup.submit') }}</span>
					</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
export default {
  created() {
    document.title = this.$t('setup.title') + " - " + process.env.VUE_APP_TITLE
  }
}
</script>


