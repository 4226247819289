export default {
  "login.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "login.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your Username and Password to log in to the system!"])},
  "login.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail address"])},
  "login.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "login.reveal_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show"])},
  "login.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "login.error_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login failed"])},
  "login.error_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login failed because the user information you entered is incorrect or has expired. To resolve this issue, please log in to the desktop application and try again."])},
  "setup.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connection settings"])},
  "setup.subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide the site URL you want to connect to."])},
  "setup.host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site URL"])},
  "setup.submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next step"])},
  "setup.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The site URL is not valid. Please try again, or contact GLABs support!"])},
  "locale.hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magyar"])},
  "locale.en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])}
}