<template>
  <div class="form">
    <form method="post">
      <h1>{{ $t('login.title') }}</h1>
      <p>{{ $t('login.subtitle') }}</p>
      <div class="alert alert-danger" v-if="$root.error">
        <img src="/auth/images/screen-lock.svg" class="screen-lock-img">
        <p class="error-message">
          <b>{{ $t('login.error_title') }}</b><br>
          {{ $t('login.error_message') }}
        </p>
      </div>
      <div class="form-group">
        <label for="username">{{ $t('login.email') }}</label>
        <input type="text" id="username" name="username" required="required" class="form-control">
      </div>
      <div class="form-group">
        <label for="password">{{ $t('login.password') }}</label>
        <div class="password-group">
          <input v-if="showPassword" type="text" v-model="password" class="form-control"/>
          <input v-else type="password" v-model="password" name="password" class="form-control" id="password"
                 required="required">
          <button :class="{ 'toggled': showPassword }" class="btn password-toggle" type="button"
                  @mousedown="revealPassword"
                  @touchstart="revealPassword"
                  @mouseup="hidePassword" @mouseleave="hidePassword" @touchend="hidePassword"
                  @touchcancel="hidePassword"
                  tabindex="-1">{{ $t('login.reveal_password') }}
          </button>
        </div>
      </div>
      <div class="row form-actions">
        <div class="col-sm-12 col-xs-12">
          <button type="submit" class="btn btn-block btn-lg btn-aperture btn-primary">
            <span>{{ $t('login.submit') }}</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  created() {
    document.title = this.$t('login.title') + " - " + process.env.VUE_APP_TITLE
  },
  data() {
    return {
      showPassword: false,
      password: null
    }
  },
  methods: {
    revealPassword() {
      this.showPassword = true;
    },
    hidePassword() {
      this.showPassword = false;
    }
  }
}
</script>


